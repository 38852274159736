<template>
  <page :footer='65' :styleWrap='{background: "#f6f6f6"}'>
    <div v-if='list.length'>
      <div style="height: 8vh; background: #fff; box-sizing: border-box; padding-right: 12px" class="f_flex f_a_c">
        <div class="f_g1">
          <van-search shape='round' v-model='searchWord' placeholder="搜索"></van-search>
        </div>
        <div class="theme_c" @click="$router.back()">取消</div>
      </div>
      <div class="content" ref='content'>
        <van-index-bar :index-list="indexList">
          <van-index-anchor index="#" />
          <div class="allPanel">
            <checkbox v-model='all' @click="allPanel">
              <div class="f_flex f_a_c">
                <div class="avatar f_flex f_a_c f_j_c theme_bg">ALL</div>
                <div>
                  <div class="name">全员</div>
                </div>
              </div>
            </checkbox>
          </div>

          <checkbox-group v-model="chooseList" ref='checkboxGroup'>
            <div v-for='(item, index) in getList' :key='index'>
              <van-index-anchor :index="item.anchor" />
              <van-cell v-for='(ii, k) in item.list' :key='k'>
                <template #title>
                  <checkbox :name="ii.userId">
                    <div class="f_flex f_a_c">
                      <div class="avatar f_flex f_a_c f_j_c theme_bg">
                        <img v-if='ii.avatar' style="width: 100%; height: 100%" :src="ii.avatar" alt="">
                        <div v-else>{{ii.userName[0]}}</div>
                      </div>
                      <div>
                        <div class="name">{{ii.userName}}</div>
                        <div class="desc">{{ii.departmentName}}</div>
                      </div>
                    </div>
                  </checkbox>
                </template>
              </van-cell>
            </div>
          </checkbox-group>
        </van-index-bar>
        <div style="height: 100px"></div>
      </div>
    </div>
    <div slot='footer' class="footer f_flex f_a_c f_j_e">
      <button @click='confirmFn' class="btn btn_radius theme_bg" style="width: 114px">确定</button>
    </div>
  </page>
</template>

<script>
import { IndexBar, IndexAnchor, Search, Cell, CheckboxGroup, Checkbox } from 'vant';
import postApi from '@/api/post'
import groupApi from '@/api/group'
import {mapActions} from 'vuex'

export default {
  components: {
    'van-index-bar': IndexBar,
    'van-index-anchor': IndexAnchor,
    'van-search': Search,
    'van-cell': Cell,
    CheckboxGroup,
    Checkbox
  },
  data: ()=> ({
    indexList: ['#', 'A', 'B', 'C', 'D', 'E', 'F',
    'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P',
    'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z'],
    searchWord: '',
    chooseList: [],
    all: false,
    list: [],
    originList: []
  }),
  watch: {
    chooseList(n) {
      if (n.length === this.originList.length) {
        this.all = true
      } else {
        this.all = false
      }
    }
  },
  computed: {
    // 搜索返回过滤
    getList() {
      const word = this.searchWord
      const list = this.list
      const propsname = 'userName'
      const propsdesc = 'allLetter'
      if(!word) return list
      const l = []
      list.filter(item => {
          if (item.anchor === word) {
          l.push(item)
          return item
        } else {
          const t = item.list.filter(ii => {
            if (ii[propsname].match(word) || ii[propsdesc].match(word)) {
              return ii
            }
          })
          if (t && t.length) {
            item.list = t
            l.push({
              anchor: item.anchor,
              list: t
            })
            return item
          }
        }
      })
      return l
    },
    getSubmitData() {
      const list = this.originList
      const choose = this.chooseList
      return list.filter(item => {
        return choose.includes(item.userId)
      })
    }
  },
  methods: {
    ...mapActions([
      'set_anchorList',
      'set_anchorType', // 1 不传 2 传一些  3全部
    ]),
    // 全选
    allPanel() {
      if (this.all) {
        this.$refs.checkboxGroup.toggleAll(true);
      } else {
        this.$refs.checkboxGroup.toggleAll();
      }
    },
    // 单选某一个
    pickItem(e) {
      if (e === '#') {
        const all = '#-0'
        const isAll = this.chooseList.some(item => item === all)
        if (isAll) {
          this.$refs.checkboxGroup.toggleAll(true);
        } else {
          this.$refs.checkboxGroup.toggleAll();
        }
      }
    },
    // 获取提醒人数据
    getRemindList() {
      postApi.postRemindList({
        groupId: this.$route.query.groupId
      }).then(res => {
        if (res.data) {
          this.originList = res.data.remindUserList // 存储原始数据
          this.list =  this.dealChangeList(this.originList, res.data.recentRemindUserList)
        }
      })
    },
    // 改造返回的list 使之可以被应用
    dealChangeList(list, recentRemindUserList) {
      const map = {}
      list.forEach(item => {
        const anchor = item.firstLetter
        if (map[anchor]) {
          map[anchor].push(item)
        } else {
          map[anchor] = [item]
        }
      });
      const anchorList = []


      if (recentRemindUserList) {
        
        anchorList.push({
          anchor: "常用",
          list: recentRemindUserList
        })
      }
      for(let i in map) {
        anchorList.push({
          anchor: i,
          list: map[i]
        })
      }


      // [{anchor: 'A', list: [...]}]
      return anchorList
    },
    confirmFn() {
      this.set_anchorList(this.getSubmitData)
      const fromPage = this.$route.query.from
        // console.log(this.chooseList)
        // console.log(this.originList)
        this.$router.back()
      if (fromPage && fromPage === 'create') {
        if (this.chooseList.length === this.originList.length) {
          this.set_anchorType(3)
        } else if (this.chooseList.length === 0) {
          this.set_anchorType(1)
        } else {
          this.set_anchorType(2)
        }
        this.$router.back()
      } else if (fromPage && fromPage === 'delGroupItem') {
        const arr = []
        this.getSubmitData.forEach(item => {
          arr.push(item.userId)
        });
        this.deleteGroupMember(arr)
      }
    },
    deleteGroupMember(arr) {
      const {groupId, companyId} = this.$route.query
      const obj = {
        groupId: groupId,
        companyId: companyId,
        type: 2,
        userList: arr
      }
      groupApi.groupUserOperate(obj).then(res => {
        if (res.data) {
          this.$toast(res.data.msg)
          setTimeout(() => {
            this.$router.back()
          }, 20)
        }
      })
    }
  },
  mounted() {
    this.getRemindList()
  }
}
</script>

<style lang="less" scoped>
.content {
  position: absolute;
  height: 92vh;
  top: 7.9vh;
  width: 100%;
  overflow: auto;
}
.allPanel {
  background: #fff;
  padding: 18px;
}
.avatar {
  width: 40px;
  height: 40px;
  border-radius: 40px;
  overflow: hidden;
  margin-right: 10px;
}
.name {
  font-size: 16px;
  color: #2D2D2D;
  line-height: 18px;
}
.desc {
  font-size: 12px;
  color: #999999;
}
.footer {
  height: 100%;
  background: #fff;
  padding: 12px;
  box-sizing: border-box;
}
</style>
